import React from 'react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import Button from '../Button';
import { Slide, Container, Image } from './HomeSlider.styled';

const HomeSlider = ({ slides }) => {
  const slider = React.useRef();

  React.useEffect(() => {
    const slide = slider.current.querySelectorAll('.slick-slide')[0];
    slide.firstChild.firstChild.lastChild.classList.add('active');
  }, []);

  const config = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
    beforeChange: (s) => {
      const slide = slider.current.querySelectorAll('.slick-slide')[s];
      slide.firstChild.firstChild.lastChild.classList.remove('active');
    },
    afterChange: (s) => {
      const slide = slider.current.querySelectorAll('.slick-slide')[s];
      slide.firstChild.firstChild.lastChild.classList.add('active');
    },
    pauseOnHover: false,
    pauseOnFocus: false
  };

  return (
    <div ref={slider}>
      <Slider {...config}>
        {slides.map((slide, i) => (
          <Slide key={i}>
            <Image image={getImage(slide.image)} alt={`${slide.title1} ${slide.title2}`} />
            <Container className="container">
              <h1>{slide.title1} <span>{slide.title2}</span></h1>
              <h2>{slide.subtitle}</h2>
              <div className="buttons">
                <Button as="a" href="#about" className="smooth-scroll" variant='primary' color='white'>
                  Dalej
                </Button>
                <Button as={Link} to="/kontakt" variant='border' color='white' contrast='#555555'>
                  Skontaktuj się z nami
                </Button>
              </div>
            </Container>
          </Slide>
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
