import React from 'react';
import {graphql, Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import HomeSlider from '../components/HomeSlider';
import Button from '../components/Button';
import OfferBlock from '../components/OfferBlock';
import LogosSlider from '../components/LogosSlider';
import * as style from '../assets/style/pages/home-page.module.scss';

const IndexPage = ({data}) => {
  const mainSliderSlides = data.homepage.slider.value.map(({value}) => ({
    image: value.image.value,
    title1: value.title1.value,
    title2: value.title2.value,
    subtitle: value.subtitle.value
  }));

  const featuredBlocks = data.homepage.featuredBlocks.value.map(({value}) => ({
    icon: value.icon.value.src,
    title: value.title.value,
    subtitle: value.subtitle.value
  }));

  const modularFurnitureSection = () => {
    const sectionData = data.modularFurniture;
    return {
      title: sectionData.title.value,
      excerpt: sectionData.excerpt.value,
      background: sectionData.mainImage.value
    };
  };

  const offerSection = () => {
    const sectionData = data.homepage.offerSection.value;
    return {
      title: sectionData.title.value,
      subtitle: sectionData.subtitle.value
    };
  };

  const offers = data.offers.edges.map(({node}) => ({
    id: node.id,
    image: node.image.value,
    title: node.title.value,
    description: node.description.value,
    order: node.order.value
  }));

  const individualProjectsSection = () => {
    const sectionData = data.homepage.individualProjectsSection.value;
    return {
      title: sectionData.title.value,
      subtitle: sectionData.subtitle.value,
      text: sectionData.text.value,
      background: sectionData.background.value
    };
  };

  const realizationsSection = () => {
    const sectionData = data.homepage.realizationsSection.value;
    return {
      title: sectionData.title.value,
      subtitle: sectionData.subtitle.value
    };
  };

  const clientsLogos = data.homepage.clientsSlider.value.map(({value}) => ({
    id: value.logo.value.childImageSharp.id,
    image: value.logo.value,
    title: value.title.value
  }));

  return (
    <Layout isHome>
      <Seo />
      <section className='section'>
        <HomeSlider slides={mainSliderSlides} />
      </section>
      <section className={`section ${style.sectionSplit}`}>
        <div className='container-fluid'>
          <div className='row'>
            <div className={`col-12 col-lg-6 ${style.individual}`}>
              <h2 className='section__title section__title--center'>{individualProjectsSection().title}</h2>
              <p>
                <strong>{individualProjectsSection().subtitle}</strong>
                {individualProjectsSection().text}
              </p>
              <Button as={Link} to='/realizacje' state={{category: 'projekty-indywidualne'}} variant='primary' color='white'>
                Zobacz przykłady
              </Button>
            </div>
            <div className={`col-12 col-lg-6 ${style.modularFurniture}`}>
              <h2 className='section__title section__title--center'>{modularFurnitureSection().title}</h2>
              <p>{modularFurnitureSection().excerpt}</p>
              <Button as={Link} to='/system-mebli-modulowych' variant='primary' color='white'>
                Zobacz szczegóły oferty
              </Button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={`section ${style.features}`}>
        <div className='container'>
          <div className='row'>
            {featuredBlocks.map((block, i) => (
              <div key={i} className={`${style.features__block} col-12 col-lg-4`}>
                <div>
                  <img src={block.icon} alt={block.title} />
                  <p>
                    <strong>{block.title}</strong>
                    {block.subtitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
      <section className={`section ${style.offer}`}>
        <div className='container'>
          <h2 className='section__title section__title--center'>
            <span>{offerSection().title}</span>
            {offerSection().subtitle}
          </h2>
          <div className={`row ${style.offer__row}`}>
            {offers.map((offer) => (
              <div key={offer.id} className='col-12 col-md-6 col-xl-4' style={{order: offer.order}}>
                <OfferBlock offer={offer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={`section ${style.realizations}`}>
        <div className='container'>
          <h2 className='section__title section__title--center'>
            <span>{realizationsSection().title}</span>
            {realizationsSection().subtitle}
          </h2>
        </div>
        <div className={`${style.realizations__row} row`}>
          {data.realizations.edges.map(({node}, i) => (
            <div key={node.id} className={`${style.realizations__realization} col-12 col-sm-6 col-md-4 col-xl-2`}>
              <GatsbyImage image={getImage(node.image.value)} alt={`realizacja-${i}`} />
            </div>
          ))}
          <div className={`${style.realizations__realizationsBtn} col-12 col-sm-6 col-md-4 col-xl-2`}>
            <Button as={Link} to='/realizacje' display='block' variant='primary' color='white'>
              Zobacz więcej naszych realizacji
            </Button>
          </div>
        </div>
      </section>
      <section className={`section ${style.clients}`}>
        <LogosSlider logos={clientsLogos} />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    homepage: cockpitHomepage {
      slider {
        value {
          value {
            image {
              value {
                childImageSharp {
                  gatsbyImageData(width: 1800, quality: 100, placeholder: BLURRED)
                }
              }
            }
            title1 {
              value
            }
            title2 {
              value
            }
            subtitle {
              value
            }
          }
        }
      }
      featuredBlocks {
        value {
          value {
            icon {
              value {
                src: publicURL
              }
            }
            title {
              value
            }
            subtitle {
              value
            }
          }
        }
      }
      offerSection {
        value {
          title {
            value
          }
          subtitle {
            value
          }
        }
      }
      individualProjectsSection {
        value {
          title {
            value
          }
          subtitle {
            value
          }
          text {
            value
          }
          background {
            value {
              childImageSharp {
                gatsbyImageData(width: 1800, quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
      realizationsSection {
        value {
          title {
            value
          }
          subtitle {
            value
          }
        }
      }
      clientsSlider {
        value {
          value {
            logo {
              value {
                childImageSharp {
                  id
                  gatsbyImageData(width: 250, height: 143, quality: 100, placeholder: BLURRED)
                }
              }
            }
            title {
              value
            }
          }
        }
      }
    }
    offers: allCockpitOffer {
      edges {
        node {
          id
          image {
            value {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  height: 150
                  quality: 100
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )
              }
            }
          }
          title {
            value
          }
          description: excerpt {
            value
          }
          order {
            value
          }
        }
      }
    }
    modularFurniture: cockpitModularFurniture {
      title {
        value
      }
      excerpt {
        value
      }
      mainImage {
        value {
          childImageSharp {
            gatsbyImageData(width: 1800, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
    realizations: allCockpitRealizations(limit: 5) {
      edges {
        node {
          id
          image {
            value {
              childImageSharp {
                gatsbyImageData(width: 290, height: 290, quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
